import React from "react";
import { Button, Container, Row, Col, Table } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";

const timeTableData = [
  {
    grade: "KG",
    duration: "29 June 2020 - 31 July 2020",
    description: "Starting from week 27",
    file: "https://dhurham-time-table.s3.amazonaws.com/G+Time+Table+New+Time+Table.pdf"
  },
  {
    grade: "Grade 1",
    duration: "29 June 2020 - 31 July 2020",
    description: "Starting from week 27",
    file: "https://dhurham-time-table.s3.amazonaws.com/G1+Time+Table+New+Time+Table.pdf"
  },
  {
    grade: "Grade 02-A",
    duration: "29 June 2020 - 31 July 2020",
    description: "Starting from week 27",
    file: "https://dhurham-time-table.s3.amazonaws.com/G2-A+Time+Table+New+Time+Table.pdf"
  },
  {
    grade: "Grade 02-B",
    duration: "29 June 2020 - 31 July 2020",
    description: "Starting from week 27",
    file: "https://dhurham-time-table.s3.amazonaws.com/G2-B+Time+Table+New+Time+Table.pdf"
  },
  {
    grade: "Grade 3",
    duration: "29 June 2020 - 31 July 2020",
    description: "Starting from week 27",
    file: "https://dhurham-time-table.s3.amazonaws.com/G3+Time+Table+New+Time+Table.pdf"
  },
  {
    grade: "Grade 4",
    duration: "29 June 2020 - 31 July 2020",
    description: "Starting from week 27",
    file: "https://dhurham-time-table.s3.amazonaws.com/G4+Time+Table+New+Time+Table.pdf"
  },
  {
    grade: "Grade 5",
    duration: "29 June 2020 - 31 July 2020",
    description: "Starting from week 27",
    file: "https://dhurham-time-table.s3.amazonaws.com/G5+Time+Table+New+Time+Table.pdf"
  },
  {
    grade: "Grade 6",
    duration: "29 June 2020 - 31 July 2020",
    description: "Starting from week 27",
    file: "https://dhurham-time-table.s3.amazonaws.com/G6+Time+Table+New+Time+Table.pdf"
  },
  {
    grade: "Grade 7",
    duration: "29 June 2020 - 31 July 2020",
    description: "Starting from week 27",
    file: "https://dhurham-time-table.s3.amazonaws.com/G7+Time+Table+New+Time+Table.pdf"
  },
  {
    grade: "Grade 8",
    duration: "29 June 2020 - 31 July 2020",
    description: "Starting from week 27",
    file: "https://dhurham-time-table.s3.amazonaws.com/G8+Time+Table+New+Time+Table.pdf"
  },
  {
    grade: "Grade 9",
    duration: "29 June 2020 - 31 July 2020",
    description: "Starting from week 27",
    file: "https://dhurham-time-table.s3.amazonaws.com/G9+Time+Table+New+Time+Table.pdf"
  },
]

const TimeTable = () => {
  return (
    <>
      <DemoNavbar />
      <div className="position-relative">
        <section className="section section-shaped pb-50">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>        
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg">
            <Container>
              <Table style={{width: "100%"}}>
                <thead>
                  <tr>
                    <th>Grade</th>
                    <th>Duration</th>
                    <th>Description</th>
                    <th>File</th>
                  </tr>
                </thead>
                <tbody>
                  { 
                    timeTableData.map(t => (
                      <tr>
                        <td>{t.grade}</td>
                        <td>{t.duration}</td>
                        <td>{t.description}</td>
                        <td><a href={`${t.file}`} target="_blank" rel="noopener noreferrer">View</a></td>
                      </tr>
                    )) 
                  }
                </tbody>
              </Table>
            </Container>
          </section>
      </div>
    </>
  )
}


export default TimeTable;