/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col, Card, CardImg, Button } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Hero from "./IndexSections/Hero.js";
import Buttons from "./IndexSections/Buttons.js";
import Inputs from "./IndexSections/Inputs.js";
import CustomControls from "./IndexSections/CustomControls.js";
import Menus from "./IndexSections/Menus.js";
import Navbars from "./IndexSections/Navbars.js";
import Tabs from "./IndexSections/Tabs.js";
import Progress from "./IndexSections/Progress.js";
import Pagination from "./IndexSections/Pagination.js";
import Pills from "./IndexSections/Pills.js";
import Labels from "./IndexSections/Labels.js";
import Alerts from "./IndexSections/Alerts.js";
import Typography from "./IndexSections/Typography.js";
import Modals from "./IndexSections/Modals.js";
import Datepicker from "./IndexSections/Datepicker.js";
import TooltipPopover from "./IndexSections/TooltipPopover.js";
import Carousel from "./IndexSections/Carousel.js";
import Icons from "./IndexSections/Icons.js";
import Login from "./IndexSections/Login.js";
import Download from "./IndexSections/Download.js";

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <Hero />
          {/* <Buttons />
          <Inputs />
          <section className="section">
            <Container>
              <CustomControls />
              <Menus />
            </Container>
          </section> */}
          {/* <Navbars /> */}
          <section className="section bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <Card className="bg-default shadow border-0">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/MAIN PHOTO-1.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-4 font-weight-bold text-white">
                        First Convocation
                      </h4>                      
                    </blockquote>
                  </Card>
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                      <i className="ni ni-settings" />
                    </div>
                    <h3>சான்றிதழ் வழங்கும் வைபவம்</h3>
                    <p className="lead">
                        எமது ஊரை பொருத்தவரையில் மிக நீண்ட காலமாக 
                        ஓர் பிரத்தியேக வகுப்பு நடத்துவதற்கு ஒரு கல்வி நிறுவனம் காணப்படவில்லை. 
                        எனவே இக்குறையை நிவர்த்தி செய்து, எமது ஊரில் உள்ள ஏனைய 
                        கல்வி நிறுவனங்கள் மற்றும் பாடசாலைகள் போன்றவற்றின் பெறுபேற்றில் 
                        அளவிடத்தக்க ஓர் மாற்றத்தினை கொண்டு வருவதே எமது அடிப்படை நோக்கமாகும். 
                        ஒரு சமூகத்தில் மாணவர்கள் மத்தியில் அறிவியல் மாற்றத்தினையும் 
                        அதன் சிந்தனை மாற்றத்தினையும் ஏற்படுத்துவது என்பது ஓர் இலகு காரியமல்ல. 
                        ஒன்று எமது மேற்படி சிந்தனைக்குள் மாணவர்களை கொண்டு வர வேண்டும் 
                        அப்படி இல்லையாயின் மாணவர்களை மூலதனமாக கொண்டுள்ளவர்கள் 
                        இதனை உணர வேண்டும். என்பதை உணர்த்தும் வகையில் எமது 
                        முதல் Convocation இனை ஆரம்பித்தோம்.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-components">
            <Container>
              {/* <Tabs />
              <Row className="row-grid justify-content-between align-items-center mt-lg">
                <Progress />
                <Pagination />
              </Row>
              <Row className="row-grid justify-content-between">
                <Pills />
                <Labels />
              </Row>
              <Alerts />
              <Typography />
              <Modals />
              <Datepicker />
              <TooltipPopover /> */}
            </Container>
          </section>
          <Carousel />


          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Now Expert Learning Center in Cloud</h2>
                </Col>
              </Row>
              <Row className="justify-content-center text-center mb-lg">
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/5e8cdf0a664eae000408545b.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Teams</span>
                        <small className="h6 text-muted">Microsoft</small>
                      </h5>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/linkelc sample logo no tag.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">The Passion That Matters</span>                        
                      </h5>
                    </div>
                  </div>
                </Col>                             
              </Row>
            </Container>
          </section>
          {/* <Icons /> */}
          {/* <Login /> */}
          {/* <Download /> */}
        </main>
        {/* <CardsFooter /> */}
      </>
    );
  }
}

export default Index;
