/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "views/Index.js";
// import Landing from "views/examples/Landing.js";
// import Login from "views/examples/Login.js";
// import Profile from "views/examples/Profile.js";
// import Register from "views/examples/Register.js";
import Events from "views/examples/Events";
import AboutUs from "views/examples/AboutUs";
import ContactUs from "views/examples/ContactUs";
import TimeTable from "views/examples/TimeTable";

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/events">
        <Events />
      </Route>
        
      <Route path="/our-courses">
        <AboutUs/>
      </Route>

      <Route path="/contact-us">
        <ContactUs />
      </Route>        
      
      <Route path="/time-table">
        <TimeTable />
      </Route>

      <Route path="/">
        <Index />
      </Route>
    </Switch>
  </Router>,
  document.getElementById("root")
);
