import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";

const Events = () => {
  return (
    <>
      <DemoNavbar />
      <div className="position-relative">
        <section className="section section-shaped pb-50">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>        
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="section section-lg">
          <Container>
            <h5>News</h5>
            <p className="lead">
              SPECIAL CLASSES FOR GRADE 6, 7 & 8
            </p>
            <p>
              Grade 6, 7 & 8 are the most important in the secondary education, 
              where a child’s basic foundation is laid in discipline, motivation and education. 
              It’s the guidance for a successful result at the G.C.E.O/Level examination undoubtedly.
            </p>
            <p>
              However the younger generation of Galhinna lacks the knowledge in the communicating language, 
              science and mathematics. 
              Their future is bleak due to this handicap. 
              It’s of course a very pathetic situation, where it will lead no-where.
            </p>
            <p>
              Regarding the aforesaid we’ve had discussions with school principals and teachers of the area, 
              and focused on about 30 weak students as a research. 
              Our aim is to give extra aiding classes in the way of Seminars, 
              discussions and regular P.T.A.meetings to these students in a fortnightly basis, 
              mainly concentrating on Language, Science and Mathematics from December 2012.
            </p>            
            <p>
              We’re sure that you would comprehend, that this project is no easy task, 
              and we have to concentrate in developing our children as well as moldering our society in many a way. 
              Galhinna inhabitants are poor in their interactions with the outside world, 
              mostly due to the language barrier.
            </p>            
            <p>
              Our earnest plea is to the educated and wealthy 
              hierarchy of our village and abroad to lend a hand in 
              achieving our aspirations and to make a healthy 
              success is our earnest endeavor for the upliftment of our village. 
              finally thank those who have already come forward to donate and
              <span>
                <strong>
                  {" "} May God the Almighty shower his blessing to each and every one of you for this 
                  unstinted contribution towards our endeavor
                </strong>
              </span>
            </p>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Events;