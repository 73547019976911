import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";

const ContactUs = () => {
  return (
    <>
      <DemoNavbar />
      <div className="position-relative">
        <section className="section section-shaped pb-50">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>        
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="section section-lg">
          <Container>
            <h4>About Us</h4>

            <p className="lead">Mailing Address</p>
            <p>Expert Learning Center</p>
            <p>219/5 Mosque Road.</p>
            <p>Galhinna. 20152</p>

            <p className="lead">Tel:</p>
            <p>+94 8131 40009</p>

            <p className="lead">Mobile:</p>
            <p>+94 777 56 3329</p>

            <p className="lead">Email:</p>
            <p>elcgalhinna@gmail.com</p>
          </Container>
        </section>
      </div>
    </>
  );
}

export default ContactUs;