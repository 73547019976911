import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";


const AboutUs = () => {
  return (
    <>
      <DemoNavbar />
      <div className="position-relative">
        <section className="section section-shaped pb-50">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>        
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="section section-lg">
          <Container>
            <h4>Our Courses</h4>
            <p className="lead">
              SLDP  - விஷேட கற்றல் அபிவிருத்தித் திட்டம்
            </p>
            <ul className="list-unstyled">
              <li>
                Classes from Grade 1  to Grade 5
                <ul>
                  <li>Basic Maths Development Group Classes</li>
                  <li>English LSRW Classes</li>
                  <li>IWMS Elocution Classes </li>
                </ul>
              </li>
            </ul>

            <p className="lead">
              ILDP  - தனிநபர் கற்றல் அபிவிருத்தித் திட்டம்
            </p>
            <ul className="list-unstyled">
              <li>
                Classes from Grade 6  to Grade 10
                <ul>
                  <li>Developing Areas of Learning Interest through Basic Maths</li>
                  <li>Self-Learning Development Plan To Develop the Concentration in History & Islam Subjects.</li>
                  <li>Computer Classes (Specially Designed For School Students ICT)</li>
                  <li>Spoken English Classes</li>
                </ul>
              </li>
            </ul>

            <p className="lead">
              Professional Courses  for School Leavers
            </p>
            <ul className="list-inline">
              <li>Certificate In Spoken English</li>
              <li>Diploma In Spoken English</li>
              <li>Certificate In Computer Studies</li>
              <li>Diploma In Computer Studies</li>
              <li>ICDL (International Computer Driving Licence)</li>
              <li>Diploma In Ms.Office</li>
              <li>Diploma In Computer Studies</li>
              <li>Diploma In Teachers’ Training</li>
            </ul>

            <p className="lead">
              Other Courses
            </p>
            <ul className="list-inline">
              <li>English for Business People</li>
              <li>Computer for Business People</li>
              <li>English & Computer Courses for School Leavers (This courses are specially designed who is waiting for higher studies and jobs in abroad)</li>
            </ul>

            <p>
              வாசிப்பு என்பது அறிவு, ஆன்மீகம், ஆற்றல் என்பவற்றின் திறவுகோள். ‘’Reading Makes Man Perfect’’ ‘’வாசிப்பு ஒரு மனிதனை முழு மனிதனாக்கும்’’
            </p>

            <p>
              உலகில் மிக முக்கியமான இரண்டு நிகழ்வுகள் இருக்கின்றன அவையாவன இரவும் பகலும் 
              இரவு என்கின்ற இருளை அகற்ற அல்லாஹ்வால் வடிவமைக்கப்பட்ட 
              ஒரு படைப்பிலக்கியம் சூரியன். எனவே இவ்வுலகம் சூரிய உதயத்தை எவ்வளவு எதிர்பார்க்கிறது? 
              இப்படி ஒரு நிகழ்வு நடைபெறாவிட்டால் இவ்வுலகின் இயக்கப்பாடு கேள்வி குறியாகிவிடும். 
            </p>

            <p>
              அறிவு எனும் பொக்கிஷத்தை நுகர துடிக்கும் ஒவ்வொரு ஆத்மாவிக்கும் 
              நூற்களின் வரவு அந்த சூரியனுக்கு ஒப்பானது. நூற்களை படிப்பது போன்று அறிவை 
              வளர்க்கும் ஓர் ஊடகத்தை கண்டெடுப்பது கடினம். நூற்கள் என்பது ஒர் தலைவனுக்கு வைரவரிகள், 
              ஓர் இரானுவ வீரனுக்கு உயிருள்ள தோட்டாக்கள், 
              ஓர் மேடை பேச்சாளனுக்கு ஒலிவாங்கி இப்படியெல்லாம் நூற்களை பற்றி பெருமை பேசலாம்.
            </p>

            <p>
              ஒழுக்கம், பண்பாடு, சமூக முன்னேற்றம் போன்ற உயரிய குணங்களை நூற்கள் தோற்றுவிக்கின்றன. 
              இவையூடாக மாணவர்கள் தமது எதிர்கால இலக்குகளை (GOALS) சரியாக அமைத்துக்கொள்ள வேண்டும். 
              சமூகத்தின் எழுச்சிக்கும் வீழ்ச்சிக்கும் மிக முக்கியமானவர்கள் மாணவர்கள். 
              ஓர் இலட்சியமிக்க சமூக அமைப்பை கட்டியெழுப்ப மாணவர்கள் தமது ஆத்மீக, 
              லெளகீக கல்வித்துறைகளை இறையச்சத்தோடு கற்று செயலாற்றுவதன் 
              மூலம் காலத்தின் அரை கூவலை சமாளிக்கக்கூடிய புத்தி ஜீவிகளாக பரிணமிக்க முடியும். 
              ஏனென்றால் வாசிப்பதன் மூலம் தனது வாழ்வை ஓர் அர்த்தமுள்ளதாக வழியனுப்ப முடியும்.
            </p>

            <p>
              பொதுவாக மாணவர்கள் தமது பாடசாலை நூற்களோடு தமது 
              வாசிப்பு உலகை சுருக்கி கொள்வது அவர்களது உயரிய அறிவிற்கு தடைக்கல்லாக 
              அமைகிறது. அறிவு, அனுபவம் என்பவை எவ்வளவு எமது வாழ்வில் 
              மாற்றத்தை கொண்டுவருகிறதோ அதைவிட ஒரு நூல், அறிவு வளர்ச்சிக்கும், 
              சிந்தனை மேம்பாட்டிற்கும் முன்னோடியாக திகழ்கிறது.
            </p>

            <p>
              அறிவு, ஆற்றல் மிக்க சமுதாயத்தின் மிக பெரும் பலம் நுல்களும் அதனை வாசிப்பதுமே. 
              கையடக்க தொலை பேசியில் விரல்கள் அழுத்துவதை விட 
              ஓர் புத்தகத்தை புரட்ட உதவுமானால், அரட்டை அடிக்க சந்தி, 
              சந்தியாக நடக்கும் கால்கள் ஓர் நூலகத்தை நோக்கி நகருமானால் 
              ஒரு உதாரணமிக்க சமூகத்தை கண்டுவிட்டு சந்தோஷத்துடன் இறந்துவிடலாம். 
              ஒவ்வொருவரும் வாசிப்பின் பக்கம் தமது கவனத்தை திருப்புவோம்.
            </p>

            <p className="lead">
              வாசிப்பு பழகக்கத்தை மேம்படுத்துவதற்கு சில ஆலோசனைகள்.
            </p>

            <p>தனக்கு விருப்பமான துறைகளை சார்ந்த புத்த்கங்களை வாசித்தல் (விளையாட்டு விருப்பமானவர்கள் அது சம்பந்தமான வற்றை வாசித்தல்)</p>
            <p>வாசிக்கும் போது ஏனோ தானோ என்று வாசிக்காமல் கவனத்தை குவித்து மனதில் மீட்டி விளங்கி வாசித்தல் வேண்டும்.</p>
            <p>வாசிக்க ஏதுவான இடங்களை தான் இருக்கும் இடத்தில் ஏற்பாடு செய்தல்</p>
            <p>மாதத்துக்கு ஒரு புத்தகத்தை வாங்கும் பழக்கத்தை உருவாக்குதல்.</p>
            <p>பரிசுப்பொருட்களுக்கு பதிலாக புத்தகங்களை வாங்கிக் கொடுக்கும் பழகக்கத்தை உருவாக்குதல்.</p>
            <p>பரிசுப்பொருட்களுக்கு பதிலாக புத்தகங்களை வாங்கிக் கொடுக்கும் பழகக்கத்தை உருவாக்குதல்.</p>
            <p>வீட்டின் முன் அறையில் (Sitting Room) அழகாக பார்வைக்கு தெரியக்கூடிய வகையில் பிரயோசனமான புத்தகங்களை வைத்தல்.</p>
            <p>ஒரு வீட்டுக்கு ஒரு வாசிகசாலை என்ற கருப்பொருளை அறிமுகப்படுத்தி செயற்படுத்துதல்.</p>

            <p>
              தான் வாசித்த நூல்கள் ஞாபகம் இல்லை என்பதை விட 
              வாசித்த எதோ ஒரு விடயத்தை மிக முக்கியமான சந்தர்ப்பத்தில் 
              எம்மை அறியாமல் பயன்படுத்தப்பட்டால் எவ்வளவு சந்தோஷமாக இருக்கும். 
              எனவே வாசித்தவைகள் எனக்கு ஞாபகம் இல்லை என கவலைபட தேவையில்லை.
            </p>

            <blockquote className="blockquote">
              <p  className="text-center mb-0">
                அறிவு, ஆற்றல் மிக்க சமுதாயத்தின் மிக பெரும் பலம் நூல்களும் அதனை வாசிப்பதுமே
              </p>
            </blockquote>
          </Container>
        </section>
      </div>
    </>
  );
}

export default AboutUs;